$(function() {
  $.ajax({
    type: "GET",
    url:  "/api/datalayer/signin_user",
  }).done(function(data){
    // 成功の場合の処理
    if(data !== null){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'userId' : data
      })
    };
  }).fail(function(){
    // エラーの場合処理
    console.log('error');
  });
})

